<template>
  <v-app>
    <v-main>
      <v-container>
        <a href="/customer">企業ログインはこちら</a>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>
